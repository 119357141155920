<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back(-2)">直播管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back(-1)">班级管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.go(0)"
            >{{ essentialInformationData.userName }}-学习记录</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <el-tabs v-model="activeName">
          <el-tab-pane label="学习记录" name="first">
            <!-- 基本数据 -->
            <div class="infoDatas">
              <span class="s1">姓名：</span>
              <span class="s2">{{ essentialInformationData.userName }}</span>
              <span class="s1">身份证号：</span>
              <span class="s2">{{ essentialInformationData.idcard }}</span>
              <span class="s1">电话：</span>
              <span class="s2">{{ essentialInformationData.mobile }}</span>
            </div>
            <!-- 检索数据 -->
            <el-form
              ref="retrievalData"
              :model="retrievalData"
              label-width="100px"
              inline
            >
              <el-form-item label="直播课节名称" prop="liveLessonName">
                <el-input
                  v-model="retrievalData.liveLessonName"
                  placeholder="直播课节名称"
                  clearable
                  size="small"
                />
              </el-form-item>

              <el-form-item label="学习状态" prop="learnState">
                <el-select
                  v-model="retrievalData.learnState"
                  placeholder="请选择"
                  clearable
                  size="small"
                >
                  <el-option
                    v-for="item in complete"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="operation">
                <el-button
                  style="width: 80px"
                  type="primary"
                  size="small"
                  class="bgc-bv"
                  round
                  @click="getData()"
                  >查询</el-button
                >
              </el-form-item>
            </el-form>
            <!-- 列表数据 -->
            <div class="framePage-scroll">
              <div class="ovy-a">
                <el-table
                  ref="multipleTable"
                  :data="tableData"
                  :height="tableHeight"
                  size="small"
                  tooltip-effect="dark"
                  style="width: 100%"
                  :header-cell-style="tableHeader"
                  stripe
                >
                  <el-table-column
                    label="序号"
                    align="center"
                    type="index"
                    :index="indexMethod"
                    width="100px"
                  />
                  <el-table-column
                    label="直播课程名称"
                    align="left"
                    prop="liveCourseName"
                    show-overflow-tooltip
                    min-width="200"
                  />
                  <el-table-column
                    label="直播课节名称"
                    align="left"
                    show-overflow-tooltip
                    prop="liveLessonName"
                    min-width="200"
                  />
                  <el-table-column
                    label="学习状态"
                    align="left"
                    prop="learnState"
                    show-overflow-tooltip
                    min-width="100"
                  ><template slot-scope="scope">
                      <span>{{getAuditName(scope.row.learnState)}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="开始学习时间"
                    align="left"
                    show-overflow-tooltip
                    prop="createTime"
                    min-width="140"
                  />
                  <el-table-column
                    label="完成学习时间"
                    align="left"
                    prop="completeTime"
                    show-overflow-tooltip
                    min-width="140"
                  />
                  <Empty slot="empty" />
                </el-table>
              </div>
            </div>
            <PageNum
              :apiData="apiData"
              @sizeChange="sizeChange"
              @getData="getData"
            />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "liveClassStudentListLearningRecords",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 初始化显示学习记录
      activeName: "first",
      // 主键id
      liveProjectUserId: "",
      // 基本信息
      essentialInformationData: {},
      // 列表数据
      listData: [],
      // 数据检索
      retrievalData: {
        liveLessonName: "", // 直播课节名称
        learnState: "", // 学习状态
      },
      // 学习状态 - 下拉数据
      complete: [],
    };
  },
  methods: {
    // 获取 - 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      params.liveProjectUserId = JSON.parse(this.$route.query.info).liveProjectUserId;
      params.liveLessonName = this.retrievalData.liveLessonName;
      params.learnState = this.retrievalData.learnState;
      this.doFetch(
        {
          url: "/liveProject/live-project/learnStudy",
          params,
          pageNum,
        },
        true,
        3
      );
    },
    // 获取学习状态
    getcompleteList() {
      const studylist = this.$setDictionary("LEARNINGSTATE", "list");
      const list = [];
      for (const key in studylist) {
        list.push({
          value: key,
          label: studylist[key],
        });
      }
      this.complete = list;
    },
    // 列表学习状态 - 字段赋值
    getAuditName(value) {
      for (let i in this.complete) {
        if (this.complete[i].value == value) {
            return this.complete[i].label;
        }
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 105;
      }
      this.tableHeight = tHeight;
    },
  },
  created() {
    this.liveProjectUserId = JSON.parse(
      this.$route.query.info
    ).liveProjectUserId;
    this.essentialInformationData = JSON.parse(this.$route.query.info);
    this.getcompleteList();
  },
};
</script>
<style lang="less">
.framePage-body .infoDatas {
  line-height: 30px;
  .s2 {
    margin-right: 30px;
    margin-left: 5px;
  }
}
</style>